import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User,
} from 'firebase/auth';
import { auth, fetchUserData, getUserFlag } from '../providers/firebase';

interface AuthContextType {
  isUserDev: boolean;
  flags: Record<string, boolean> | null;
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  user: User | null;
  isUserScribe: boolean | null;
  isLoggingIn: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<null | any>(null);
  const [isUserScribe, setIsUserScribe] = useState<boolean | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [flags, setFlags] = useState<Record<string, boolean> | null>({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setIsAuthenticated(true);
        const userData = await fetchUserData(currentUser.uid);
        setIsUserScribe(Boolean(userData?.flags['scribe']));
        getUserFlag(currentUser.uid).then((x) => setFlags(x));
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setIsUserScribe(false);
        setIsLoggingIn(false); // End logging in
      }
    });
    return () => unsubscribe();
  }, []);

  const isUserDev = !!flags?.['dev-mode'];

  const login = () => {
    setIsLoggingIn(true);
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        setUser(result.user);
        setIsAuthenticated(true);
      })
      .catch((error) => {
        setIsLoggingIn(false); // End logging in
        setIsUserScribe(false);
        console.error(error);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setIsAuthenticated(false);
        setIsUserScribe(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isUserDev,
        flags,
        isAuthenticated,
        login,
        logout,
        user,
        isUserScribe,
        isLoggingIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
