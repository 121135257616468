import { SegmentsDurations } from 'ava-label-types';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { functions } from '../providers/firebase';
import { Button } from 'antd';
import { useAuth } from '../providers/AuthProvider';

function computeTotalDuration(data: SegmentsDurations): number {
  return Object.values(data).reduce(
    (total, entry) => total + entry.time_validated,
    0
  );
}

const formatDuration = (seconds: number) =>
  [Math.floor(seconds / 3600), Math.floor((seconds % 3600) / 60), seconds % 60]
    .map((unit) => String(Math.floor(unit)).padStart(2, '0'))
    .join(':');

const Metrics = () => {
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [totalDurationPerScribe, setTotalDurationPerScribe] =
    useState<SegmentsDurations>({});
  const [isLoading, setIsLoading] = useState(true);

  const { isUserDev } = useAuth();

  console.log(totalDurationPerScribe, totalDuration, isLoading);
  const fetchFunction = async () => {
    try {
      const response = await httpsCallable<void, SegmentsDurations>(
        functions,
        'getSegmentsDurations'
      )();
      setTotalDurationPerScribe(response.data);
      const computedDuration = computeTotalDuration(response.data);
      setTotalDuration(computedDuration);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const reComputeMetrics = async () => {
    try {
      setIsLoading(true);
      const callableFunction = httpsCallable(
        functions,
        'computeScribeMetrics',
        {
          timeout: 1200000, // 20 minutes
        }
      );
      await callableFunction();
      await fetchFunction();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFunction();
  }, []);
  return (
    <div>
      <h1>Metrics</h1>
      {/*convert to HH:MM:SS*/}
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          {isUserDev && (
            <div>
              <Button
                onClick={async () => {
                  await reComputeMetrics();
                }}
              >
                Re compute metrics (only use when it's necessary)
              </Button>
            </div>
          )}
          <div style={{ marginBottom: 10 }}>
            <strong>Total duration:</strong>{' '}
            {totalDuration ? formatDuration(totalDuration) : '00:00:00'}
          </div>
          <div>
            {Object.keys(totalDurationPerScribe).map((scribe) => (
              <div key={scribe}>
                <strong>{totalDurationPerScribe[scribe].name}: </strong>
                {totalDurationPerScribe[scribe]?.time_validated
                  ? new Date(
                      totalDurationPerScribe[scribe].time_validated * 1000
                    )
                      .toISOString()
                      .substr(11, 8)
                  : '00:00:00'}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Metrics;
